import React from 'react'
import Navbar from '../Navbar'
import Social from '../Social'
import './Menu.css'


function Menu() {
  return (
    <div className='menuContainer'>
      <Navbar></Navbar>
      <Social></Social>
    </div>
  )
}

export default Menu