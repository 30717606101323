import BigNumber from 'bignumber.js';
import './Stake.css'
import Menu from "../components/Menu"

import bloodDrop from'../img/blood-drop.png';
import wolfIcon from'../img/wolficon.png';
import LoaderIcon from '../components/LoaderIcon';


import { useEffect, useState } from "react"

import { currentChain, currentChainName, currentChainNetwork, contractAddress, bloodAdrs, bloodDecimals, lockerAdrs } from "../constants/chain"

import { computeTimeLeftBeforeUnlock } from '../services/timeframeHandler'

import nftAbi from "../abi/upgradableNft.json"
import bloodAbi from "../abi/blood.json"
import lockerAbi from "../abi/lockerAbi.json"

import { ethers } from "ethers";
import { Contract, uint256, stark, RpcProvider } from "starknet"
import {
  connectWallet,
  chainId,
  getProvider,
  walletAddress,
  handleConnectWallet,
  addWalletChangeListener,
  removeWalletChangeListener,
} from "../services/wallet.service"
import { truncateAddress, truncateHex, formatAddress } from '../services/address.service'
import { truncateNumber } from '../services/mathHandler'
import CardListLock from '../components/CardListLock'

export default function Stake(){

  const [address, setAddress] = useState();
  const [chain, setChain] = useState();
  const [isConnected, setConnected] = useState(false);
  const [account, setAccount] = useState();
  const [isApprovedForAll, setIsApprovedForAll] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [bloodBalance, setBloodBalance] = useState(0);
  const [myNfts, setMyNfts] = useState([]);
  const [myLockedNfts, setMyLockedNfts] = useState([]);
  const [timeLeftBeforeUnlock, setTimeLeftBeforeUnlock] = useState([]);
  const [timeframeIds, setTimeframeIds] = useState([]);
  const [timestampsLocked, setTimestampsLocked] = useState([]);
  const [timestampsLastHarvested, setTimestampsLastHarvested] = useState([]);
  const [safeAdrs, setSafeAdrs] = useState(0);
  const [provider, setProvider] = useState();

  

  useEffect(() => {
    const handler = async () => {
      const wallet = await connectWallet();
      if(wallet){
        await setWalletInfos(wallet);
      }
      setIsLoading(false)
    }

    (async () => {
      await handler();
    })()

  }, []);

  async function setWalletInfos(wallet){
      setAddress(wallet.selectedAddress)
      let chainIdVal = chainId(wallet)

      setChain(chainIdVal)
      setConnected(wallet.isConnected)
      if (wallet.account) {
        setAccount(wallet.account)
      }

      if(chainIdVal == currentChain){
        let provider = getProvider(chainIdVal);
        if(provider) {
          setProvider(provider)
          await updateBalanceSupply(wallet.selectedAddress, wallet.account, provider);
        }
      }

  }

  const handleConnectClick = async () => {
    const wallet = await connectWallet();
    setWalletInfos(wallet);
  }

  async function updateBalanceSupply(address, walletAccount, provider){

    const myBalance = await getBalance(address, provider);

    let promisesArray = [];

    promisesArray.push(getBloodBalance(address, provider));
    promisesArray.push(getIsApprovedForAll(address, provider, lockerAdrs));
    promisesArray.push(getSafeByAdrs(address, provider));

    for (let i = 0; i < myBalance; i++) {
      promisesArray.push(tokenOfOwnerByIndex(address, i, provider));
    }
    const results = await Promise.all(promisesArray);
    setBloodBalance(results[0]);
    setIsApprovedForAll(results[1]);
    setSafeAdrs(results[2]);

    const myNftsResult = results.slice(3);
    setMyNfts(myNftsResult);

    if(results[2] != 0n){
      const myLockedNftsResult = await getLockedNfts(results[2], provider);
      setMyLockedNfts(myLockedNftsResult);

      promisesArray = [];

      if(myLockedNftsResult.length > 0){
        promisesArray.push(getTimeframesLocked(myLockedNftsResult, provider));
        promisesArray.push(getTimestampsLocked(myLockedNftsResult, provider));
        promisesArray.push(getTimestampsLastHarvest(myLockedNftsResult, provider));
        const results = await Promise.all(promisesArray);
        const timeframeIdsLockedNfts = results[0];
        const timestampLockedNfts = results[1];
        setTimeframeIds(timeframeIdsLockedNfts);
        setTimestampsLocked(timestampLockedNfts);
        setTimestampsLastHarvested(results[2]);
        // const timeframeIdsLockedNfts =  await getTimeframesLocked(myLockedNftsResult, walletAccount);
        // const timestampLockedNfts = await getTimestampLockedNfts(myLockedNftsResult, walletAccount);
        // console.log(account)
        const currentBlock = await provider.getBlock();
        // console.log(currentBlock);
        setTimeLeftBeforeUnlock(computeTimeLeftBeforeUnlock(timeframeIdsLockedNfts, timestampLockedNfts, currentBlock.timestamp));
      }
    }
  }

  async function getLockedNfts(safeAdrs, walletAccount){
    const lockedBalance = await getBalance(safeAdrs, walletAccount);
    let promisesArray = [];
    for (let i = 0; i < lockedBalance; i++) {
      promisesArray.push(tokenOfOwnerByIndex(safeAdrs, i, walletAccount));
    }
    return Promise.all(promisesArray);
  }

  async function getTimeframesLocked(lockedNftIds, walletAccount){
    let promisesArray = [];
    for (let i = 0; i < lockedNftIds.length; i++) {
      promisesArray.push(getTimeframeId(lockedNftIds[i], walletAccount));
    }
    return Promise.all(promisesArray);
  }

  async function getTimestampsLocked(lockedNftIds, walletAccount){
    let promisesArray = [];
    for (let i = 0; i < lockedNftIds.length; i++) {
      promisesArray.push(getTimestampLocked(lockedNftIds[i], walletAccount));
    }
    return Promise.all(promisesArray);
  }
  async function getTimestampsLastHarvest(lockedNftIds, walletAccount){
    let promisesArray = [];
    for (let i = 0; i < lockedNftIds.length; i++) {
      promisesArray.push(getLastHarvest(lockedNftIds[i], walletAccount));
    }
    return Promise.all(promisesArray);
  }
  

  async function tokenOfOwnerByIndex(ownerAdrs, tokenId, walletAccount){
    try {
      const contract = new Contract(nftAbi, contractAddress, walletAccount);
      const res = await contract.tokenOfOwnerByIndex(ownerAdrs, {low: tokenId, high: 0});
      return Number(res.tokenId.low);
    }
    catch(error){
      console.log('tokenOfOwnerByIndex ', error.message)
      return 0;
    }
  }

  async function getBalance(ownerAdrs, walletAccount){
    try {
      const contract = new Contract(nftAbi, contractAddress, walletAccount);
      const res = await contract.balanceOf(ownerAdrs);
      return Number(res.balance.low);
    }
    catch(error){
      console.log('getBalance ', error.message)
      return 0;
    }
  }
  

  async function getIsApprovedForAll(ownerAdrs, walletAccount, operatorAdrs){
    try {
      const contract = new Contract(nftAbi, contractAddress, walletAccount);
      const res = await contract.isApprovedForAll(ownerAdrs, operatorAdrs);
      return Number(res.isApproved);
    }
    catch(error){
      console.log('getIsApprovedForAll ', error.message)
      return 0;
    }
  }

  async function getSafeByAdrs(ownerAdrs, walletAccount){
    try {
      const contract = new Contract(lockerAbi, lockerAdrs, walletAccount);
      const res = await contract.getSafeByAdrs(ownerAdrs);
      return res;
    }
    catch(error){
      console.log('getSafeByAdrs ', error.message)
      return 0;
    }
  }

  async function getTimeframeId(tokenId, walletAccount){
    try {
      const contract = new Contract(lockerAbi, lockerAdrs, walletAccount);
      const res = await contract.getTimeframeId(tokenId);
      return Number(res);
    }
    catch(error){
      console.log('getTimeframeId ', error.message)
      return 0;
    }
  }

  async function getTimestampLocked(tokenId, walletAccount){
    try {
      const contract = new Contract(lockerAbi, lockerAdrs, walletAccount);
      const res = await contract.getTimestampLocked(tokenId);
      return Number(res);
    }
    catch(error){
      console.log('getTimestampLocked ', error.message)
      return 0;
    }
  }

  async function getLastHarvest(tokenId, walletAccount){
    try {
      const contract = new Contract(lockerAbi, lockerAdrs, walletAccount);
      const res = await contract.getLastHarvest(tokenId);
      return Number(res);
    }
    catch(error){
      console.log('getLastHarvest ', error.message)
      return 0;
    }
  }

  async function getBloodBalance(ownerAdrs, walletAccount){
    try {
      const contract = new Contract(bloodAbi, bloodAdrs, walletAccount);
      const res = new BigNumber(await contract.balanceOf(ownerAdrs));
      return truncateNumber(res.dividedBy(bloodDecimals).toFixed(), 6);
    }
    catch(error){
      console.log('getBloodBalance ', error.message)
      return 0;
    }
  }


  function renderMint(){
    if(isLoading){
      return(
      <>
        Loading
        <LoaderIcon></LoaderIcon>
      </>)
    }
    if(isConnected && chain == currentChain){
      return (
        <>
          <div className="MyNftsDescription">
            {/* <div className='BloodBalanceContainer'>
              <div className='BloodBalanceText'>Total wolves locked</div>
              <div className='BloodBalanceValueTextContainer'>
                <div className='BloodBalanceValue'>
                  100
                </div>
                <img src={wolfIcon}></img>
              </div>
            </div> */}
            <div className='BloodBalanceContainer'>
              <div>Connected as</div>
              <div className='walletAdrsValue'>{truncateAddress(address)}</div>
            </div>
            <div className='BloodBalanceContainer'>
              <div className='BloodBalanceText'>Your $BLOOD balance </div>
              <div className='BloodBalanceValueTextContainer'>
                <div className='BloodBalanceValue'>
                  {bloodBalance}
                </div>
                <img src={bloodDrop}></img>
              </div>
            </div>
            <div className='MyNftsDescriptionTitle'>Lock your Wolves and earn $BLOOD</div>
            <div className='MyNftsDescriptionTimeCostContainer'>
              <div className='MyNftsDescriptionTimeCost'>
                <div className='MyNftsDescriptionTime'>2 weeks</div>
                <div  className='MyNftsDescriptionCostValueImgText'>
                  <div className='MyNftsDescriptionCostValue'>6</div>
                  <img src={bloodDrop}></img>
                  <div className='MyNftsDescriptionCostText'>per day</div>
                </div>
              </div>
              <div className='MyNftsDescriptionTimeCost'>
                <div className='MyNftsDescriptionTime'>1 month</div>
                <div  className='MyNftsDescriptionCostValueImgText'>
                  <div className='MyNftsDescriptionCostValue'>8</div>
                  <img src={bloodDrop}></img>
                  <div className='MyNftsDescriptionCostText'>per day</div>
                </div>
              </div>
              <div className='MyNftsDescriptionTimeCost'>
              <div className='MyNftsDescriptionTime'>2 months</div>
                <div  className='MyNftsDescriptionCostValueImgText'>
                  <div className='MyNftsDescriptionCostValue'>10</div>
                  <img src={bloodDrop}></img>
                  <div className='MyNftsDescriptionCostText'>per day</div>
                </div>
              </div>
              <div className='MyNftsDescriptionTimeCost'>
              <div className='MyNftsDescriptionTime'>3 months</div>
                <div  className='MyNftsDescriptionCostValueImgText'>
                  <div className='MyNftsDescriptionCostValue'>12</div>
                  <img src={bloodDrop}></img>
                  <div className='MyNftsDescriptionCostText'>per day</div>
                </div>
              </div>
              <div className='MyNftsDescriptionTimeCost'>
              <div className='MyNftsDescriptionTime'>6 months</div>
                <div  className='MyNftsDescriptionCostValueImgText'>
                  <div className='MyNftsDescriptionCostValue'>17</div>
                  <img src={bloodDrop}></img>
                  <div className='MyNftsDescriptionCostText'>per day</div>
                </div>
              </div>
              <div className='MyNftsDescriptionTimeCost'>
              <div className='MyNftsDescriptionTime'>1 year</div>
                <div  className='MyNftsDescriptionCostValueImgText'>
                  <div className='MyNftsDescriptionCostValue'>24</div>
                  <img src={bloodDrop}></img>
                  <div className='MyNftsDescriptionCostText'>per day</div>
                </div>
              </div>
            </div>
          </div>
          {myNfts.length > 0 &&           
          // {true &&           
            <div className='MyNftsContainer'>
              <div className="MyNftsTitle">
                My NFTs
              </div>
              <CardListLock tokenIds={myNfts} isLockOrUnlock={'lock'} account={account} isApprovedForAll={isApprovedForAll} provider={provider}></CardListLock>
              {/* <CardListLock tokenIds={[1,2,3,4,5,18]} isLockOrUnlock={'lock'} account={account}></CardListLock> */}
            </div>
          }
          {myLockedNfts.length > 0 &&  
          <div className='MyLockedNftsContainer'>
            <div className="MyLockedNftsTitle">
              My Locked NFTs
            </div>
            <CardListLock tokenIds={myLockedNfts} isLockOrUnlock={'unlock'} account={account} timeLeftBeforeUnlock={timeLeftBeforeUnlock} timeframeIds={timeframeIds} timestampsLocked={timestampsLocked} timestampsLastHarvested={timestampsLastHarvested}></CardListLock>
            {/* <CardListLock tokenIds={[1,2,3,4,5,6,8,9,18]} isLockOrUnlock={'unlock'} timeLeftBeforeUnlock={[1,0,3,0,5,0,8,9,18]} account={account}></CardListLock> */}
          </div>
          }
        </>
      )
      
    }
    if(isConnected) {
      return (
        <>
          <div className="changeNewtork">
            Please connect to {currentChainName}
          </div>
        </>
      )
    }
    return (
      <>
        <button className="connectWallet" onClick={handleConnectClick}>
          Connect Wallet
        </button>
        <p>First connect your wallet.</p>      
      </>
    )
  }

  return(
    <>
      <Menu></Menu>
      <div className="StakeContainer">
        {renderMint()}
      </div>
    </>
  )
}