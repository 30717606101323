// import { ReactComponent as Gitbook } from "../../../assets/icons/gitbook.svg";
import { ReactComponent as Twitter } from "../../icons/twitter.svg";
import { ReactComponent as Discord } from "../../icons/discord.svg";
import './Social.css'
export default function Social() {
    return (
        <div className="socialRow">            
            <a className="socialLink" href="https://twitter.com/Gen0_Stark">
                <Twitter></Twitter>
            </a>

            {/* <a className="socialLink" href="https://twitter.com/Gen0_Stark">
                <Discord></Discord>
            </a> */}
        </div>
    );
}
