import { currentChain, currentChainName, currentChainNetwork, contractAddress, bloodAdrs, bloodDecimals, lockerAdrs } from "../constants/chain"
import { CallData, Contract } from "starknet"
import nftAbi from "../abi/upgradableNft.json"
import lockerAbi from "../abi/lockerAbi.json"

export async function handleLockNft(nftId, timeframe, account, provider) {
  try{
    const locker = new Contract(lockerAbi, lockerAdrs, account);
    const tx = await locker.lock(nftId, timeframe, Math.floor(Math.random() * 10000000));
    await account.waitForTransaction(tx.transaction_hash);
  }
  catch(error){console.log(error.message)}
}

export async function handleUnlockNft(nftId, account, provider) {
  await handleHarvestNft(nftId, account, provider);
}

export async function handleHarvestNft(nftId, account, provider) {
  try{
    const locker = new Contract(lockerAbi, lockerAdrs, account);
    const tx = await locker.harvest(nftId);
    await account.waitForTransaction(tx.transaction_hash);
  }
  catch(error){console.log(error.message)}
}

export async function handleApproveForAll(account, provider) {
  try{
    const nftContract = new Contract(nftAbi, contractAddress, account);
    const tx = await nftContract.setApprovalForAll(lockerAdrs, 1);
    await account.waitForTransaction(tx.transaction_hash);
  }
  catch(error){console.log(error.message)}
}

