import { NavLink } from "react-router-dom"
import './Navbar.css'
import { useState } from 'react';
export default function Navbar() {
    const [isClickedTrade, setIsClickedTrade] = useState(false);

    return (
        <div className="navbarContainer">
            <NavLink className='navbarlink' to='/'>
                Home
            </NavLink>
            {/* <NavLink className='navbarlink' to='/mint'>
                Mint
            </NavLink> */}
            <NavLink className='navbarlink' to='/lock'>
                Lock
            </NavLink>
            {/* <NavLink className='navbarlink' to='/galery'>
                Galery
            </NavLink> */}
            {/* <a className="tradelink" href="https://mintsquare.io/starknet"> */}
            <div className="tradelink" onClick={() => setIsClickedTrade(!isClickedTrade)}>
                Trade
                {isClickedTrade && (
                    <div className="tradelinkDropdown">
                        <a className="tradelink" href="https://pyramid.market/collection/0x485209349294398d62ad18849b8ec0940ab7fe63117fb59eba2ab7446d78394">
                            Pyramid
                        </a>
                        {/* <a className="tradelink" href="https://flexing.gg/starknet/collection/0x0485209349294398d62ad18849b8ec0940ab7fe63117fb59eba2ab7446d78394">
                            Flex
                        </a> */}
                    </div>
                )}
            </div>
            {/* <a className="tradelink" href="https://flexing.gg/starknet/collection/0x0485209349294398d62ad18849b8ec0940ab7fe63117fb59eba2ab7446d78394">
                Trade on Flex
            </a> */}
        </div>
    )
  }
