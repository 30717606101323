import BigNumber from 'bignumber.js';

export const currentChain = "SN_MAIN";
export const currentChainName = "Mainnet";
export const currentChainNetwork = "mainnet-alpha";
export const contractAddress = "0x0485209349294398d62ad18849b8ec0940ab7fe63117fb59eba2ab7446d78394";
export const lockerAdrs = "0x02d76f00dd54729ff1a54b0718789984b4a671397459b970a2ca90a30997aea2";
export const bloodAdrs = "0x0129b50605f60facc0dd97661ac5e6c8044a3aefa9b21a4786774eb51caa2093";

// export const currentChainNetwork = "https://alpha4-1.starknet.io";
// export const currentChain = "SN_GOERLI";
// export const currentChainName = "Testnet";
// export const contractAddress = "0x0450c177b5bd3289f9d90ff116f875b71ba60efe159c846b0cd6263c621d7576";
// export const lockerAdrs = "0x02b7be6a40d81264d6912cb617515f5da1bd7197ae6a30d2da81e9ca8fc825a1";
// export const bloodAdrs = "0x00a53eb5f1cb8f04cae453c8f1584cc32dade64839a62bbc0daec08423ded5fb";

export const bloodDecimals = new BigNumber(10).pow(18);