import styles from './Card.module.css';
// import Image from 'next/image';


const Card = ({id, img}) => {
    return (
        <div className={styles.cardContainer}>
            <img className={styles.cardImg} alt="" src={img} width="220" height="220"/>
            <div className={styles.cardId}>
                Stark #{id}
            </div>
        </div>
    )
}

export default Card
