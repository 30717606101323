import Menu from "../components/Menu"
import './Galery.css'
export default function Galery(){
  return(
    <>
      <Menu></Menu>
      <div className="galeryContainer">
          Under construction
      </div>
    </>

  )
}