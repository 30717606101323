import { Oval } from 'react-loader-spinner';
function LoaderIcon() {

  return (
    <div className='loaderContainer'>
      <Oval
        height={100}
        width={100}
        color="antiquewhite"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="antiquewhite"
        strokeWidth={5}
        strokeWidthSecondary={4}
      />
    </div>
  )
}

export default LoaderIcon