/* global BigInt */
import { ethers } from "ethers";

const timeframesById = {
  0: 1314000,
  1: 2628000,
  2: 5256000,
  3: 7884000,
  4: 15768000,
  5: 31536000,
  6: 60,
  7: 600,
}

const revwardsById = {
  0: BigInt("69444444400000"),
  1: BigInt("92592590000000"),
  2: BigInt("115740740000000"),
  3: BigInt("138888880000000"),
  4: BigInt("196759250000000"),
  5: BigInt("277777777000000"),
  6: BigInt("69444444400000"),
  7: BigInt("92592590000000"),
}

// const timeframesById = {
//   0: 1314000,
//   1: 2628000,
//   2: 5256000,
//   3: 7884000,
//   4: 15768000,
//   5: 31536000,
// }

// const revwardsById = {
  
//   0: BigInt(69444444000000),
//   1: BigInt(92592590000000),
//   2: BigInt(115740740000000),
//   3: BigInt(138888880000000),
//   4: BigInt(196759250000000),
//   5: BigInt(277777770000000),
// }

export function computeTimeLeftBeforeUnlock(timeframeIdsLockedNfts, timestampLockedNfts, currentBlockTimestamp){
  let timeLeftArray = [];
  for (let i = 0; i < timeframeIdsLockedNfts.length; i++) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeframe = timeframesById[timeframeIdsLockedNfts[i]];
    const timeLeft = timeframe - (currentTime - timestampLockedNfts[i]);

    if(timeLeft < 0){
      // if(currentBlockTimestamp > timestampLockedNfts[i] + timeframe) 
      timeLeftArray.push(0);
      // else
      //   timeLeftArray.push(60);
    }
    else {
      // timeLeftArray.push(timeLeft);
      timeLeftArray.push(timeframe - (currentBlockTimestamp - timestampLockedNfts[i]));
    }
      
  }
  // console.log(timeLeftArray);
  return timeLeftArray;
}

export function computePendingRewards(timeframeId, timeLeftBeforeUnlock, timestampLocked, timestampLastHarvested){
  const timeframe = timeframesById[timeframeId];
  let pendingRewards = 0;
  if(timestampLastHarvested > timestampLocked){
    pendingRewards = BigInt((timeframe - (timestampLastHarvested - timestampLocked) - timeLeftBeforeUnlock).toString()) * revwardsById[timeframeId];
  }
  else {
    pendingRewards = BigInt((timeframe - timeLeftBeforeUnlock).toString()) * revwardsById[timeframeId];
  }
  
  return ethers.formatEther(pendingRewards);
}

export function convertTimestampToDate(timestamp){
  if(timestamp - Math.floor(Date.now() / 1000) < 86400) {
    return new Date(timestamp * 1000).toLocaleTimeString();
  }
  var date = new Date(timestamp * 1000).toISOString().slice(0,10);
  return date;
}