import Menu from "../components/Menu"
import './Home.css'
import presentation1 from '../img/wolf_base.png';
// import presentation1 from '../img/baseWolf.png';

export default function Home(){
  return(
    <div className="homeContainer">
        <Menu></Menu>
        <div className="presentationContainer">
          <div className="mainPresentationContainer">
            <div className="presentationTextContainer">
              <div className="presentationTextTitle">
                WELCOME TO THE STARKS COMMUNITY
              </div>
              <div className="presentationText">
                The Starks is a collection of 200 Wolf profile pictures stored on the blockchain as an NFT. Each of them is a unique digital collectible living on the StarkNet blockchain.
                Your Wolf NFT acts as a membership card and grants access to members-only benefits,
                the first of which is access to THE DEN, a private discord channel where the community will discuss and decide the future of the project.
              </div>
            </div>
            {/* <div className="presentationImg"> */}
            <img className="presentationImg" src={presentation1} />
          </div>
          <div className="distribContainer">
            <div className="distribTitle">
              LOCKING MECHANISM
            </div>
            <div className="distribText">
              We introduced a locking mechanism to reward long-term holders. You have the possibility to lock your NFT for a certain period.
              The longer you lock, the more $BLOOD tokens you'll eearn over time. $BLOOD is the main curency of the Starks.
            </div>
          </div>
          <div className="distribContainer">
            <div className="distribTitle">
              BUILDING A VIBRANT ECOSYSTEM
            </div>
            <div className="distribText">
              Our long-term goal is to build a vibrant ecosystem of defi apps, on-chain games around the Starks and bring utilities to our collection.
              To achieve that we collaborate with artists, developers and other projects.
              The community has the power to decide the future of the project and has already chosen our next steps. More precisely, the community has decided to :
              Build an autocompounder to generate yields by leveraging Starknet farms.
            </div>
          </div>
        </div>
    </div>
  )
}