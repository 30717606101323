import Card from "../Card"
import styles from './CardListLock.module.css';
import {handleLockNft, handleUnlockNft, handleHarvestNft, handleApproveForAll} from '../../services/txHandler'
import { truncateNumber } from "../../services/mathHandler"
import { useEffect, useState } from "react"
import { convertTimestampToDate, computePendingRewards } from "../../services/timeframeHandler"

import bloodDrop from'../../img/blood-drop.png';



export default function CardListLock({tokenIds, isLockOrUnlock, timeframeIds, timeLeftBeforeUnlock, timestampsLocked, timestampsLastHarvested, isApprovedForAll, account, provider}){
    let selectedTimeframesDict = {};

    for (const tokenId in tokenIds) {
        selectedTimeframesDict[tokenIds[tokenId]] = "1";
    }
    const [selectedTimeframe, setSelectedTimeframe] = useState(selectedTimeframesDict);

    const handleSelectTimeframeChange = (e) => {
        setSelectedTimeframe(prevState => ({
            ...prevState,
            [e.target[0].id]: e.target.value
        }));
    };

    return (
        <div className={styles.cardsContainer}>
            {tokenIds.map((tokenId, index) => {
                return (
                    <div className={styles.cardAndButtonContainer} key={tokenId}>        
                        <Card
                        id={tokenId}
                        // img={"http://localhost:3000/imageWolf/"+ tokenId +".png"}
                        img={"https://www.thestarks.art/imageWolf/"+ tokenId +".png"}
                        />
                        {isLockOrUnlock == 'lock' && isApprovedForAll == 1 &&
                            <div className={styles.buttonContainer}>
                                <button id={tokenId} className={styles.lockButton} onClick={(e) => {handleLockNft(e.target.id, selectedTimeframe[e.target.id], account, provider)}}>
                                    Lock
                                </button>
                                <select className={styles.selectTimeframe} onChange={handleSelectTimeframeChange}>
                                    <option id={tokenId} value="0">2 Weeks</option>
                                    <option id={tokenId} value="1">1 Month</option>
                                    <option id={tokenId} value="2">2 Months</option>
                                    <option id={tokenId} value="3">3 Months</option>
                                    <option id={tokenId} value="4">6 Months</option>
                                    <option id={tokenId} value="5">1 year</option>
                                    {/* <option id={tokenId} value="6">60 Sec</option>
                                    <option id={tokenId} value="7">600 Sec</option> */}
                                </select>
                            </div>
                        }
                        {isLockOrUnlock == 'lock' && isApprovedForAll == 0 &&
                            <div className={styles.buttonContainer}>
                                <button id={tokenId} className={styles.lockButton} onClick={(e) => {handleApproveForAll(account, provider)}}>
                                    Approve
                                </button>
                            </div>
                        }

                        {isLockOrUnlock == 'unlock' &&
                            <div className={styles.buttonContainer}>
                                {timeLeftBeforeUnlock[index] == 0 &&
                                    <div className={styles.unlockedContainer}>
                                        <div className={styles.pendingRewardsContainer}>
                                            <div className={styles.pendingRewardsValue}>{truncateNumber(computePendingRewards(timeframeIds[index], 0, timestampsLocked[index], timestampsLastHarvested[index]), 6)} </div>
                                            <img src={bloodDrop}></img>
                                            <div className={styles.pendingRewardsText}> to harvest</div>
                                        </div>  
                                        <button id={tokenId} className={styles.harvestAndUnlockButton} onClick={(e) => {handleUnlockNft(e.target.id, account, provider)}}>
                                            Harvest & Unlock
                                        </button>
                                    </div>
                                }
                                {timeLeftBeforeUnlock[index] > 0 &&
                                <div className={styles.lockedContainer}>
                                    <div className={styles.lockTime}>
                                        Locked until {convertTimestampToDate(Date.now() / 1000 + timeLeftBeforeUnlock[index])}
                                    </div>
                                    <div className={styles.pendingRewardsContainer}>
                                        <div className={styles.pendingRewardsValue}>{truncateNumber(computePendingRewards(timeframeIds[index], timeLeftBeforeUnlock[index], timestampsLocked[index], timestampsLastHarvested[index]), 6)}</div>
                                        <img src={bloodDrop}></img>
                                        <div className={styles.pendingRewardsText}> to harvest</div>
                                    </div>   
                                    <button id={tokenId} className={styles.harvestButton} onClick={(e) => {handleHarvestNft(e.target.id, account, provider)}}>
                                        Harvest
                                    </button>
                                </div>
                                }
                            </div>
                        }
                    </div>
                );
            })}


        </div>  
    );
}