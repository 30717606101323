import {HashRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
// import Mint from './pages/Mint';
// import WaitMint from './pages/WaitMint';
import Stake from './pages/Stake';
import Galery from './pages/Galery';
import './app.css';

function App() {
  return (
    <div className="App">
      <HashRouter>
          <Routes>
              <Route path='/' element={<Home></Home>}></Route>
              {/* <Route path='/mint' element={<Mint></Mint>}></Route> */}
              {/* <Route path='/mint' element={<WaitMint></WaitMint>}></Route> */}
              <Route path='/lock' element={<Stake></Stake>}></Route>
              <Route path='/galery' element={<Galery></Galery>}></Route>
          </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
